import * as constants from "../../constants";

const salesChannelInitState = {
  channels: [],
  commissionTypes: []
};

export function salesChannel(state = salesChannelInitState, action) {
  switch (action.type) {
    case constants.SALES_CHANNEL_LIST_SUCCESS:
      return {
        ...state,
        channels: action.payload.list
      }
    case constants.SALES_CHANNEL_COMMISSION_TYPE_SUCCESS:
      return {
        ...state,
        commissionTypes: action.payload.types
      }
    case constants.SALES_CHANNEL_CHANNEL_TYPE_SUCCESS:
      return {
        ...state,
        channelTypes: action.payload.types
      }
    default:
      return state;
  }
}
