// 
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, TextField, Tooltip } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import Button from "../Button"
import ReactTable from "react-table";
import moment from "moment";
import { KeyboardDatePicker, TimePicker } from "@material-ui/pickers";

const EditMode = ({ openDialog, setOpenDialog, rateClasses, rateTypes, zones, tdsps, doCreateTDSPRate, user_id, rowTDSPName, setRowTDSPName, rowRateClassName, setRowRateClassName, rowRateTypeName, setRowRateTypeName, rowZoneName, setRowZoneName, rowStartDate, setRowStartDate }) => {
    const localtz = moment.tz.guess();
    const startDate = moment(rowStartDate).format("YYYY-MM-DD")
    const startTime = moment(rowStartDate).format("HH:mm:ss")

    const matching_tdsp_id = tdsps?.filter(tdsp => tdsp.name === rowTDSPName)
    const tdsp_id = matching_tdsp_id?.length > 0 ? matching_tdsp_id[0].ID : 0

    const matching_rate_type = rateTypes?.filter(type => type.type === rowRateTypeName)
    const rate_type_id = matching_rate_type?.length > 0 ? matching_rate_type[0].id : 0

    const matching_rate_class = rateClasses?.filter(type => type.type === rowRateClassName)
    const rate_class_id = matching_rate_class?.length > 0 ? matching_rate_class[0].id : 0

    const matching_zone_id = zones?.filter(zone => zone.zone === rowZoneName && zone.tdsp_id === tdsp_id)
    const zone_id = matching_zone_id?.length > 0 ? matching_zone_id[0].id : 0

    const [selectedDate, setSelectedDate] = useState(startDate);
    const [selectedTime, setSelectedTime] = useState(startTime);
    const [validTime, setValidTime] = useState(true)
    const [newRateRequest, setNewRateRequest] = useState({
        tdsp_id: 0,
        rate_type_id: 0,
        class_type_id: 0,
        zone_id: 0,
        rate: 0.0,
        start_date: moment(`${moment(selectedDate).format("YYYY-MM-DD")}T${selectedTime}`).toISOString(),
        user_id: user_id
    })

    const handleTDSPRateChange = (event) => {
        setNewRateRequest(prevState => ({
            ...prevState,
            tdsp_id: parseInt(tdsp_id, 10),
            rate_type_id: parseInt(rate_type_id, 10),
            class_type_id: parseInt(rate_class_id, 10),
            zone_id: parseInt(zone_id, 10),
            rate: parseFloat(event.target.value)
        }));
    }

    const validateTime = () => {
        if ((selectedDate !== startDate) && (validTime === false)) {
            setValidTime(true)
        }
        if ((selectedDate === startDate) && (selectedTime < startTime) && (validTime === true)) {
            setValidTime(false)
        }
    }

    useEffect(() => {

        validateTime();

        setNewRateRequest(prevState => ({
            ...prevState,
            start_date: moment(`${moment(selectedDate).format("YYYY-MM-DD")}T${selectedTime}`).toISOString()
        }));
    }, [selectedDate, selectedTime]);

    useEffect(() => {
        if (openDialog) {
            const formattedDate = moment(rowStartDate).format("YYYY-MM-DD");
            const formattedTime = moment(rowStartDate).format("HH:mm:ss");

            setSelectedDate(formattedDate);
            setSelectedTime(formattedTime);

            setNewRateRequest(prevState => ({
                ...prevState,
                start_date: moment(`${moment(selectedDate).format("YYYY-MM-DD")}T${selectedTime}`).toISOString()
            }));
        }
    }, [openDialog, rowStartDate]);


    return (
        <Dialog
            open={openDialog}
            ariaHideApp={false}
            fullWidth={true}
            maxWidth={"md"}
        >
            <DialogTitle style={{ marginRight: '20px', marginLeft: '20px' }}>
                Edit Rate
            </DialogTitle>
            <DialogContent style={{ overflow: 'hidden' }}>
                <DialogContentText style={{ marginRight: '20px', marginLeft: '20px' }}>
                    <Grid container>
                        <Grid style={{ gap: '20px', display: 'flex', alignItems: 'center' }} item sm={12}>
                            <KeyboardDatePicker
                                label="Start Date*"
                                dateformat="YYYY-MM-DD"
                                value={moment(selectedDate).tz(localtz)}
                                minDate={moment(startDate).tz(localtz)}
                                onChange={() => null}
                                onAccept={(value) => {
                                    setSelectedDate(moment(value).format("YYYY-MM-DD"));
                                }}
                                views={["year", "month", "date"]}
                                style={{ margin: "15px 0px", width: "200px" }}
                            />
                            <TimePicker
                                label="Start Time*"
                                format="HH:mm:ss"
                                value={moment(selectedTime, "HH:mm:ss")}
                                onChange={() => { }}
                                onAccept={(value) => {
                                    const newTime = moment(value).format("HH:mm:ss");

                                    if (selectedDate === startDate && newTime < startTime) {
                                        setValidTime(false);
                                        setSelectedTime(newTime);
                                    } else {
                                        setValidTime(true);
                                        setSelectedTime(newTime);
                                    }
                                }}
                                views={["hours", "minutes", "seconds"]}
                                style={{ margin: "15px 0px", width: "200px" }}
                            />
                        </Grid>
                        {(validTime === false) &&
                            <Grid item sm={12} style={{ marginTop: "20px" }}>
                                <div style={{ color: "red" }}>{"Please Select a Valid Time, Please Select a Time Greater Than the Rates Initial Start Time"}</div>
                            </Grid>
                        }
                        <Grid style={{ marginTop: '20px' }} item sm={12}>
                            <FormControl variant="outlined" size="small" fullWidth>
                                <TextField
                                    label='TDSP*'
                                    type="text"
                                    size="small"
                                    color="primary"
                                    style={{ width: '100%' }}
                                    InputLabelProps={{ shrink: true }}
                                    variant="outlined"
                                    defaultValue={rowTDSPName}
                                    disabled={true}
                                />
                            </FormControl>
                        </Grid>
                        <Grid style={{ marginTop: '20px' }} item sm={12}>
                            <FormControl variant="outlined" size="small" fullWidth>
                                <TextField
                                    label='Rate Type*'
                                    type="text"
                                    size="small"
                                    color="primary"
                                    style={{ width: '100%' }}
                                    InputLabelProps={{ shrink: true }}
                                    variant="outlined"
                                    defaultValue={rowRateTypeName}
                                    disabled={true}
                                />
                            </FormControl>
                        </Grid>
                        <Grid style={{ marginTop: '20px' }} item sm={12}>
                            <FormControl variant="outlined" size="small" fullWidth>
                                <TextField
                                    label='Rate Class*'
                                    type="text"
                                    size="small"
                                    color="primary"
                                    style={{ width: '100%' }}
                                    InputLabelProps={{ shrink: true }}
                                    variant="outlined"
                                    defaultValue={rowRateClassName}
                                    disabled={true}
                                />
                            </FormControl>
                        </Grid>
                        <Grid style={{ marginTop: '20px' }} item sm={12}>
                            <FormControl variant="outlined" size="small" fullWidth>
                                <TextField
                                    label='Rate Zone (Optional)'
                                    type="text"
                                    size="small"
                                    color="primary"
                                    style={{ width: '100%' }}
                                    InputLabelProps={{ shrink: true }}
                                    variant="outlined"
                                    defaultValue={rowZoneName}
                                    disabled={true}
                                />
                            </FormControl>
                        </Grid>
                        <Grid style={{ marginTop: '20px' }} item sm={12}>
                            <FormControl variant="outlined" size="small" fullWidth>
                                <TextField
                                    label="Rate*"
                                    type="number"
                                    size="small"
                                    color="primary"
                                    style={{ width: '100%' }}
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{
                                        step: "0.0001",
                                        min: "0",
                                    }}
                                    variant="outlined"
                                    onChange={handleTDSPRateChange}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContentText>
            </DialogContent>
            <DialogActions style={{ marginRight: '20px', marginLeft: '20px' }}>
                <Button
                    color="secondary"
                    variant="contained"
                    style={{
                        float: "right",
                        marginRight: 5,
                        marginBottom: 10,
                    }}
                    onClick={() => {
                        doCreateTDSPRate(newRateRequest, 0, 100)
                        setNewRateRequest({
                            tdsp_id: 0,
                            rate_type_id: 0,
                            class_type_id: 0,
                            zone_id: 0,
                            rate: 0.0,
                            start_date: "",
                            user_id: user_id
                        });
                        setValidTime(true)
                        setOpenDialog(false)
                    }}
                    disabled={newRateRequest.rate <= 0.0 || newRateRequest.rate === "" || isNaN(newRateRequest.rate) || validTime === false}
                >
                    Update
                </Button>
                <Button
                    onClick={() => {
                        setNewRateRequest({
                            tdsp_id: 0,
                            rate_type_id: 0,
                            class_type_id: 0,
                            zone_id: 0,
                            rate: 0.0,
                            start_date: "",
                            user_id: user_id
                        });
                        setValidTime(true)
                        setOpenDialog(false)
                    }}
                    color="primary"
                    variant="contained"
                    style={{
                        float: "right",
                        marginRight: 20,
                        marginBottom: 10,
                    }}
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}

const RateTable = ({ tdsp, tdsps, rateClasses, rateTypes, zones, user_id, doCreateTDSPRate, openDialog, setOpenDialog }) => {

    const [rowTDSPName, setRowTDSPName] = useState(null)
    const [rowRateClassName, setRowRateClassName] = useState(null)
    const [rowRateTypeName, setRowRateTypeName] = useState(null)
    const [rowZoneName, setRowZoneName] = useState(null)
    const [rowStartDate, setRowStartDate] = useState(null)

    const columns = [
        {
            Header: "ID",
            accessor: "rate_id",
            Cell: ({ value }) => (<div style={{ textAlign: 'center' }}>{value}</div>),
        },
        {
            Header: "TDSP",
            accessor: "tdsp_name",
            Cell: ({ value }) => (<div style={{ textAlign: 'center' }}>{value}</div>),
        },
        {
            Header: "Rate Type",
            accessor: "rate_type",
            Cell: ({ value }) => (<div style={{ textAlign: 'center' }}>{value}</div>),
        },
        {
            Header: "Class Type",
            accessor: "class_type",
            Cell: ({ value }) => (<div style={{ textAlign: 'center' }}>{value}</div>),
        },
        {
            Header: "Zone",
            accessor: "zone",
            Cell: ({ value }) => (<div style={{ textAlign: 'center' }}>{value}</div>),
        },
        {
            Header: "Rate",
            accessor: "rate",
            Cell: ({ value }) => (<div style={{ textAlign: 'center' }}>{value}</div>),
        },
        {
            Header: "Start Date",
            accessor: "start_date",
            Cell: ({ value }) => (<div style={{ textAlign: 'center' }}>{new Date(value).toISOString().split("T")[0]}</div>),
        },
        {
            Header: "End Date",
            accessor: "end_date",
            Cell: ({ value }) => (value ? <div style={{ textAlign: 'center' }}>{new Date(value).toISOString().split("T")[0]}</div> : ""),
        },
        {
            Header: "",
            resizable: false,
            filterable: false,
            width: 150,
            Cell: (row) => (
                <div>
                    <Tooltip placement="top" title="View/Edit Data">
                        <Button
                            style={{ marginRight: '5px' }}
                            variant="contained"
                            color="secondary"
                            size="small"
                            onClick={() => {
                                console.log(row.original)
                                setRowTDSPName(row.original.tdsp_name)
                                setRowRateClassName(row.original.class_type)
                                setRowRateTypeName(row.original.rate_type)
                                setRowZoneName(row.original.zone)
                                setRowStartDate(row.original.start_date)
                                setOpenDialog(true)
                            }}
                        >
                            Edit
                        </Button>
                    </Tooltip>
                </div>
            ),
        },
    ];

    return (
        <div>
            <ReactTable
                data={tdsp.rate_list}
                columns={columns}
                style={{ textAlign: 'center' }}
                className="-striped -highlight"
                defaultPageSize={10}
                defaultSortDesc
                filterable
            />

            <EditMode
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
                rateClasses={rateClasses}
                rateTypes={rateTypes}
                zones={zones}
                tdsps={tdsps}
                user_id={user_id}
                doCreateTDSPRate={doCreateTDSPRate}
                rowTDSPName={rowTDSPName}
                setRowTDSPName={setRowTDSPName}
                rowRateClassName={rowRateClassName}
                setRowRateClassName={setRowRateClassName}
                rowRateTypeName={rowRateTypeName}
                setRowRateTypeName={setRowRateTypeName}
                rowZoneName={rowZoneName}
                setRowZoneName={setRowZoneName}
                rowStartDate={rowStartDate}
                setRowStartDate={setRowStartDate}
            />

        </div>
    );
};

RateTable.propTypes = {};

export default RateTable;