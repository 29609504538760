import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Button from "../../Button";
import TextField from "@material-ui/core/TextField";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import { noselect } from "../../../styles/common.css";
import { Error, Warning } from "@material-ui/icons";
import Snackbar from '@material-ui/core/Snackbar';
import { useStyles } from "./styles";
import {
  fetchDpps,
  fetchCreateDpp,
  fetchValidateDpp,
  fetchCancelDpp,
} from "../../../actions/search";
import selectAccountFlagsContainer from "../../../selectors/accountFlags";
import {
  TableRow,
  TableCell,
  Table,
  TableBody,
  TableHead,
  TableContainer,
  FormControl,
  FormControlLabel,
} from "@material-ui/core/";
import IconButton from "@material-ui/core/IconButton";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import currency from "../../../util/currency-formatter";
import moment from "moment";

const CancelDialog = ({ onClose, onSubmit, open }) => {
  return <Dialog onClose={onClose} open={open}>
    <DialogTitle>Cancel DPP</DialogTitle>
    <DialogContent>
      This action will cancel the selected DPP and add any remaining charges to the next statement.
      <br />
      Are you sure you want to continue?
      <br /><br />
      <strong>Note: Switch Hold must be removed separately.</strong>
    </DialogContent>
    <DialogActions>
      <Button
        color="primary"
        onClick={() => { onSubmit(); onClose() }}
        variant="contained"
      >
        Yes
      </Button>
      <Button
        color="secondary"
        onClick={onClose}
        variant="contained"
      >
        No
      </Button>
    </DialogActions>
  </Dialog>
}

const MemberDpp = (props) => {
  const classes = useStyles();
  const {
    dpps,
    adminUser,
    accountID,
    accountBalance,
    onFetchDpps,
    onFetchValidateDpp,
    onFetchCreateDpp,
    onFetchCancelDpp,
  } = props;

  const [dppAmount, setDPPAmount] = useState(0);
  const [percent, setPercent] = useState('');
  const [amount, setAmount] = useState('');
  const [remainingUnpaidBalance, setRemainingUnpaidBalance] = useState(0);
  const [installments, setInstallments] = useState('');
  const [allowedAccount, setAllowedAccount] = useState(null)
  const [disabled, setDisabled] = useState(false)
  const [error, setError] = useState('')
  const [dppExists, setDppExists] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openCheckDialog, setOpenCheckDialog] = useState(false);
  const [initialPaymentType, setInitialPaymentType] = useState('percent');
  const [dppToCancel, setDPPToCancel] = useState(null)

  const validateInput = () => {
    if (disabled || dppExists || !accountBalance || !amount || !installments) {
      return false
    }

    const parsedAmount = parseFloat(amount)
    if (!parsedAmount || !parseInt(installments)) {
      return false
    }

    if (parsedAmount < (accountBalance.get("last_bill_amount") * 0.50)) {
      return false
    }

    if (remainingUnpaidBalance > 0) {
      return false
    }

    return dppAmount <= accountBalance.get("balance_due") || dppAmount > 0
  };

  useEffect(() => {
    if (accountID) {
      onFetchDpps(accountID)
    }
  }, [accountID]);

  useEffect(() => {
    if (allowedAccount !== null) {
      setDisabled(!(adminUser.roles.includes('ops') || adminUser.roles.includes('super')))
    }
  }, [allowedAccount]);

  const handleInstallmentsChange = (e) => {
    let value = e.target.value;
    if (value === '' || (!isNaN(value) && value >= 1 && value <= 5)) {
      setInstallments(value);
    }
  };

  const handleAmountChange = (e) => {
    let value = e.target.value;
    if (/^\d*\.?\d*$/.test(value) || value === '') {
      const percentPaid = (100 * value / accountBalance.get("last_bill_amount")).toFixed(2)
      updateAmounts(value);
      setPercent(percentPaid);
      checkAmountPaid(percentPaid)
    }
  };

  const handlePercentageChange = (e) => {
    let value = e.target.value;
    if (/^\d*\.?\d*$/.test(value) || value === '') {
      setPercent(value);
      const newInitialAmount = (accountBalance.get("last_bill_amount") * (value / 100))

      // Always round up on fractional cents
      updateAmounts((Math.ceil(newInitialAmount * 100) / 100).toFixed(2));
      checkAmountPaid(value)
    }
  };

  function checkAmountPaid(percentPaid) {
    if (percentPaid < 50) {
      setError('Initial amount must be at least 50% of current balance.')
    } else if (percentPaid > 100) {
      setError('Initial amount cannot be greater than statement amount.')
      setPercent(50);
      const newInitialAmount = (accountBalance.get("last_bill_amount") * 0.50)
      updateAmounts((Math.ceil(newInitialAmount * 100) / 100).toFixed(2));
    } else {
      setError('')
    }
  }

  function updateAmounts(dollarValue) {
    setAmount(dollarValue);
    const dpp = Math.min(
      accountBalance.get("last_bill_amount") - dollarValue,
      accountBalance.get("balance_due")
    ).toFixed(2)

    setDPPAmount(dpp)
    setRemainingUnpaidBalance((accountBalance.get("balance_due") - dpp).toFixed(2))
  }

  function checkDPPExists() {
    for (let d of dpps) {
      if (d.get("originating_statement_id") == accountBalance.get("statement_id") &&
        d.get("status") == "active" || d.get("status") == "error") {
        return true
      }
    }
    return false
  }

  const handleOpenDialog = async () => {
    setAllowedAccount(null)
    setDppExists(checkDPPExists())

    await onFetchValidateDpp(accountID).then(async res => {
      setAllowedAccount(res.reason ? res.reason : null)
      setOpenDialog(true)
    }).catch(err => {
      setError(err.message)
      setOpenDialog(true)
    })
  };

  const handleCloseDialog = () => {
    setError('')
    setOpenDialog(false)
  };

  const handleCloseCheckDialog = () => {
    setError('')
    setOpenCheckDialog(false)
  };

  const handleValidateDPP = async () => {
    if (percent < 50) {
      setError('Initial amount must be at least 50% of current balance.')
    } else if (allowedAccount !== null) {
      setOpenCheckDialog(true)
    } else {
      await handleCreateDPP()
    }
  }

  const handleCreateDPP = async () => {
    handleCloseCheckDialog()
    await onFetchCreateDpp({
      account_id: accountID,
      originating_statement_id: accountBalance.get("statement_id"),
      deferred_balance: parseFloat(dppAmount).toFixed(2),
      installment_count: installments
    }).then(res => {
      handleCloseDialog()
      onFetchDpps(accountID)
    }).catch(err => {
      setError(err.message)
      setOpenCheckDialog(false)
    })
  }

  const handleCancelDPP = () => {
    onFetchCancelDpp({ account_id: accountID, dpp_id: dppToCancel }).then(() => {
      onFetchDpps(accountID)
    }).catch((err) => {
      setError(err.message)
    })
  }

  return (
    <Card className={classes.root}>
      <Grid container>
        <Grid item md={6}>
          <h4 style={{ marginLeft: "25px" }}>Deferred Payment Plan</h4>
        </Grid>
        {
          accountBalance &&
          <Grid item md={6}>
            <div style={{ float: "right", marginBottom: "10px" }}>
              <IconButton
                aria-label="Make Payment Plan"
                size="medium"
                title="Make Payment Plan"
                classes={{ label: classes.iconButtonLabel }}
                color="primary"
                onClick={() => {
                  if (accountBalance) {
                    setPercent(50);
                    updateAmounts((accountBalance.get("last_bill_amount") * 0.50).toFixed(2));
                    handleOpenDialog()
                  }
                }}
              >
                <AddCircleOutlineIcon fontSize="large" />
              </IconButton>
            </div>
          </Grid>
        }
      </Grid>
      <TableContainer>
        <Table
          className={classes.table}
          aria-label="collapsible table"
          style={{ fontSize: 14 }}
        >
          <TableHead>
            <TableRow>
              <TableCell align="center">Deferred Balance</TableCell>
              <TableCell align="center">Installments</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Created Date</TableCell>
              <TableCell align="center">Completion Date</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dpps && (
              <Fragment>{
                dpps.map(x =>
                  <TableRow>
                    <TableCell align="center">
                      {currency(x.get("deferred_balance"))}
                    </TableCell>
                    <TableCell align="center">
                      {x.get("installment_count")}
                    </TableCell>
                    <TableCell align="center">
                      {x.get("status")}
                    </TableCell>
                    <TableCell align="center">
                      {moment.utc(x.get("created")).format("YYYY-MM-DD")}
                    </TableCell>
                    <TableCell align="center">
                      {x.get("completion_date") && moment.utc(x.get("completion_date")).format("YYYY-MM-DD")}
                    </TableCell>
                    <TableCell align="center">
                      <Button
                        onClick={() => setDPPToCancel(x.get("id"))}
                        color="secondary"
                        disabled={!(x.get("status") === "active" || x.get("status") === "error")}
                      >
                        Cancel
                      </Button>
                    </TableCell>
                  </TableRow>
                )
              }</Fragment>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        onClose={() => handleCloseDialog()}
        aria-labelledby="customized-dialog-title"
        open={openDialog}
      >
        <div className={classes.paymentRoot}>
          <Card className={classes.createNoteContainer}>
            <div className={`${classes.menuBar} ${noselect}`}>
              <span>Create DPP</span>
              <CloseIcon onClick={() => handleCloseDialog()} />
            </div>
            <Grid container style={{ marginTop: '10px' }}>
              {accountBalance && <Fragment>
                <Grid item md={6}>
                  <div style={{ marginLeft: "20px" }}>
                    <div><b>Balance Due</b></div>
                    <span>$ {parseFloat(accountBalance.get("balance_due")).toFixed(2)}</span>
                  </div>
                </Grid>
                <Grid item md={6}>
                  <div style={{ marginLeft: "20px" }}>
                    <div><b>Last Bill Amount</b></div>
                    <span>$ {parseFloat(accountBalance.get("last_bill_amount")).toFixed(2)}</span>
                  </div>
                </Grid>
              </Fragment>}
            </Grid>
            <FormControl className={classes.paymentForm} style={{ paddingBottom: '5px !important' }}>
              <Grid container>
                <Grid item xs={12} style={{ marginLeft: "10px", marginRight: "30px", marginBottom: "20px" }}>
                  <div style={{ marginBottom: '10px' }}><label>Initial Payment Input</label></div>
                  <RadioGroup
                    style={{ width: "100%" }}
                    defaultValue={initialPaymentType}
                    value={initialPaymentType}
                    name="status-group"
                    onChange={e => setInitialPaymentType(e.target.value)}
                    row
                  >
                    <FormControlLabel
                      value={"percent"}
                      disabled={disabled}
                      style={{ marginLeft: '0px' }}
                      control={<Radio inline style={{ padding: '1px' }} />}
                      label="% of Bill"
                    />
                    <FormControlLabel
                      value={"amount"}
                      disabled={disabled}
                      style={{ marginLeft: '0px' }}
                      control={<Radio inline style={{ padding: '1px' }} />}
                      label="$ amount"
                    />
                  </RadioGroup>
                </Grid>
              </Grid>
              {
                initialPaymentType === "percent" &&
                <TextField
                  label={`Enter Initial Payment %`}
                  placeholder="0.00"
                  onChange={handlePercentageChange}
                  value={percent}
                  disabled={disabled}
                  type="money"
                  required
                  InputLabelProps={{ shrink: true }}
                  className={classes.paymentText}
                />
              }
              {
                initialPaymentType === "amount" &&
                <TextField
                  label={`Enter Initial Payment In Dollars`}
                  placeholder="0.00"
                  onChange={handleAmountChange}
                  value={amount}
                  disabled={disabled}
                  type="money"
                  required
                  InputLabelProps={{ shrink: true }}
                  className={classes.paymentText}
                />
              }
              <TextField
                label={`Enter Installments Count`}
                placeholder="0"
                onChange={handleInstallmentsChange}
                value={installments}
                disabled={disabled}
                type="number"
                required
                InputLabelProps={{ shrink: true }}
                className={classes.paymentText}
              />
            </FormControl>
            <Grid container style={{ marginBottom: '15px', marginTop: '-20px' }}>
              {accountBalance && <Fragment>
                <Grid item xs={4}>
                  <div style={{ marginLeft: "25px" }}>
                    <div><b>Initial Payment</b></div>
                    <span>$ {(amount ? parseFloat(amount).toFixed(2) : '-')}</span>
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div style={{ marginLeft: "25px" }}>
                    <div><b>DPP Amount</b></div>
                    <span>$ {dppAmount}</span>
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div style={{ marginLeft: "25px" }}>
                    <div><b>Initial Payment Unpaid</b></div>
                    <span style={{ color: remainingUnpaidBalance > 0 ? "red" : "black" }}>
                      $ {remainingUnpaidBalance}
                    </span>
                  </div>
                </Grid>
              </Fragment>}
            </Grid>
            {
              error &&
              <div style={{ color: 'red', marginLeft: "25px", marginBottom: '15px' }}>{error}</div>
            }

            {
              allowedAccount &&
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <span style={{
                  backgroundColor: 'orange',
                  padding: '10px',
                  paddingLeft: '22px',
                  color: 'black',
                  width: '100%',
                }}>
                  <Warning /> {allowedAccount}
                </span>
              </div>
            }
            {
              dppExists &&
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <span style={{
                  backgroundColor: 'red',
                  padding: '10px',
                  paddingLeft: '22px',
                  color: 'white',
                  width: '100%',
                }}>
                  <Error /> A DPP already exists for this statement.
                </span>
              </div>
            }
            <div
              className={classes.optionContainer}
              style={{
                borderTop: "2px solid #ddd",
              }}
            >
              <Button
                color="secondary"
                onClick={async () => await handleCloseDialog()}
              >
                Cancel
              </Button>
              <Button
                className={
                  validateInput() ? classes.enableButton : classes.disableButton
                }
                color="primary"
                onClick={async () => { await handleValidateDPP() }}
              >
                Create DPP
              </Button>
            </div>
          </Card>
          <Dialog
            onClose={() => handleCloseCheckDialog()}
            aria-labelledby="customized-dialog-title"
            open={openCheckDialog}
          >
            <DialogTitle
              className={classes.confirmDialog}
              id="customized-dialog-title"
              onClose={() => handleCloseCheckDialog()}
            >
              <span>Please Confirm DPP</span>
            </DialogTitle>
            <DialogContent dividers>
              Some account validation checks failed. Are you sure you want to continue creating a DPP?
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={() => handleCloseCheckDialog()} color="secondary">
                Close
              </Button>
              <Button
                className={classes.enableButton}
                autoFocus
                onClick={async () => await handleCreateDPP()}
                color="primary"
              >
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </Dialog>
      <CancelDialog
        onClose={() => setDPPToCancel(null)}
        onSubmit={handleCancelDPP}
        open={dppToCancel !== null}
      />
    </Card>
  );
};

MemberDpp.propTypes = {
  accountBalance: PropTypes.object,
};

const mapStateToProps = (state) => selectAccountFlagsContainer();

const mapDispatchToProps = (dispatch) => ({
  onFetchDpps: accountID => dispatch(fetchDpps(accountID)),
  onFetchValidateDpp: accountID => dispatch(fetchValidateDpp(accountID)),
  onFetchCreateDpp: accountID => dispatch(fetchCreateDpp(accountID)),
  onFetchCancelDpp: params => dispatch(fetchCancelDpp(params)),
  dispatch,
});

const ConnectedMemberDpp = connect(
  mapStateToProps,
  mapDispatchToProps
)(MemberDpp);

export default ConnectedMemberDpp;
