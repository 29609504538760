import * as constants from "../../constants";

const initialState = {
    tdsp: {
        rate_list: [],
        total: 0,
        limit: 0,
        page: 0,
        hasNext: false,
    }
}

export default function tdsp(state = initialState, action) {
    switch (action.type) {
        case constants.LIST_TDSP_RATES_SUCCESS:
            return {
                ...state,
                tdsp: {
                    rate_list: action.payload.tdsp_rate_list,
                    total: action.payload.total,
                    limit: action.payload.limit,
                    page: action.payload.page,
                    hasNext: action.payload.hasNext,
                }
            }
        case constants.LIST_TDSP_RATE_CLASSES_SUCCESS:
            return {
                ...state,
                rate_classes: action.payload.list
            }
        case constants.LIST_TDSP_RATE_TYPES_SUCCESS:
            return {
                ...state,
                rate_types: action.payload.list
            }
        case constants.LIST_TDSP_ZONES_SUCCESS:
            return {
                ...state,
                tdsp_zones: action.payload.list
            }
        case constants.TDSP_SUCCESS:
            return {
                ...state,
                tdsp_list: action.payload.tdsps
            }
        default:
            return state;
    }
}