import React, { useEffect, useState } from "react";
import RateTable from "./RateTable";
import Grid from "@material-ui/core/Grid";
import Button from "../Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import { FormControl, MenuItem } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import moment from "moment";
import { KeyboardDatePicker, TimePicker } from "@material-ui/pickers";

const CreateMode = ({ openDialog, setOpenDialog, rateClasses, rateTypes, zones, tdsps, doCreateTDSPRate, user_id }) => {
    const time = moment.tz();
    const localtz = moment.tz.guess();
    const date = time.clone().tz(localtz);
    const startDate = moment(date).format("YYYY-MM-DD")
    const startTime = moment(date).format("HH:mm:ss")
    const [selectedDate, setSelectedDate] = useState(startDate);
    const [selectedTime, setSelectedTime] = useState(startTime);
    const [validTime, setValidTime] = useState(true)
    const [newRateRequest, setNewRateRequest] = useState({
        tdsp_id: 0,
        rate_type_id: 0,
        class_type_id: 0,
        zone_id: 0,
        rate: 0.0,
        start_date: moment(`${moment(selectedDate).format("YYYY-MM-DD")}T${selectedTime}`).toISOString(),
        user_id: user_id
    })
    const [tdspSelected, setTDSPSelected] = useState(false)

    const handleTDSPChange = (event) => {
        setNewRateRequest(prevState => ({
            ...prevState,
            tdsp_id: parseInt(event.target.value, 10)
        }));

        setTDSPSelected(true)
    }

    const handleTDSPRateTypeChange = (event) => {
        setNewRateRequest(prevState => ({
            ...prevState,
            rate_type_id: parseInt(event.target.value, 10)
        }));
    }

    const handleTDSPRateClassChange = (event) => {
        setNewRateRequest(prevState => ({
            ...prevState,
            class_type_id: parseInt(event.target.value, 10)
        }));
    }

    const handleTDSPZoneChange = (event) => {
        setNewRateRequest(prevState => ({
            ...prevState,
            zone_id: parseInt(event.target.value, 10)
        }));
    }

    const handleTDSPRateChange = (event) => {

        setNewRateRequest(prevState => ({
            ...prevState,
            rate: parseFloat(event.target.value)
        }));
    }

    const validateTime = () => {
        if ((selectedDate !== startDate) && (validTime === false)) {
            setValidTime(true)
        }
        if ((selectedDate === startDate) && (selectedTime < startTime) && (validTime === true)) {
            setValidTime(false)
        }
    }

    useEffect(() => {
        
        validateTime();

        setNewRateRequest(prevState => ({
            ...prevState,
            start_date: moment(`${moment(selectedDate).format("YYYY-MM-DD")}T${selectedTime}`).toISOString()
        }));
    }, [selectedDate, selectedTime]);

    useEffect(() => {
        if (openDialog) {
            setSelectedDate(moment(date).format("YYYY-MM-DD"))
            setSelectedTime(moment(date).format("HH:mm:ss"))
            setNewRateRequest(prevState => ({
                ...prevState,
                start_date: moment(`${moment(selectedDate).format("YYYY-MM-DD")}T${selectedTime}`).toISOString()
            }));
        }
    }, [openDialog])

    return (
        <Dialog
            open={openDialog}
            ariaHideApp={false}
            fullWidth={true}
            maxWidth={"md"}
        >
            <DialogTitle style={{ marginRight: '20px', marginLeft: '20px' }}>
                Create New Rate
            </DialogTitle>
            <DialogContent style={{ overflow: 'hidden' }}>
                <DialogContentText style={{ marginRight: '20px', marginLeft: '20px' }}>
                    <Grid container>
                        <Grid style={{ gap: '20px', display: 'flex', alignItems: 'center' }} item sm={12}>
                            <KeyboardDatePicker
                                label="Start Date*"
                                dateformat="YYYY-MM-DD"
                                value={moment(selectedDate).tz(localtz)}
                                minDate={moment(startDate).tz(localtz)}
                                onChange={() => null}
                                onAccept={(value) => {
                                    setSelectedDate(moment(value).format("YYYY-MM-DD"))
                                }
                                }
                                views={["year", "month", "date"]}
                                style={{ margin: "15px 0px", width: "200px" }}
                                disabled={!tdspSelected}
                            />
                            <TimePicker
                                label="Start Time*"
                                format="HH:mm:ss"
                                value={moment(selectedTime, "HH:mm:ss")}
                                onChange={() => { }}
                                onAccept={(value) => {
                                    if ((selectedDate === startDate) && (moment(value).format("HH:mm:ss") < startTime)) {
                                        setValidTime(false)
                                        setSelectedTime(moment(value).format("HH:mm:ss"))
                                    } else {
                                        setValidTime(true)
                                        setSelectedTime(moment(value).format("HH:mm:ss"))
                                    }
                                }}
                                views={["hours", "minutes", "seconds"]}
                                style={{ margin: "15px 0px", width: "200px" }}
                                disabled={!tdspSelected}
                            />
                        </Grid>
                        {(validTime === false) &&
                            <Grid item sm={12} style={{ marginTop: "20px" }}>
                                <div style={{ color: "red" }}>{"Please Select a Valid Time, Make Sure it is Greater Than Your Current Time."}</div>
                            </Grid>
                        }
                        <Grid style={{ marginTop: '20px' }} item sm={12}>
                            <FormControl variant="outlined" size="small" fullWidth>
                                <TextField
                                    label="TDSP*"
                                    type="text"
                                    size="small"
                                    color="primary"
                                    style={{ width: '100%' }}
                                    InputLabelProps={{ shrink: true }}
                                    variant="outlined"
                                    select
                                    onChange={handleTDSPChange}
                                >
                                    {tdsps && tdsps.sort((a, b) => a.name.localeCompare(b.name)).map(item => <MenuItem key={item.ID} value={item.ID}>{item.name}</MenuItem>)}
                                </TextField>
                            </FormControl>
                        </Grid>
                        <Grid style={{ marginTop: '20px' }} item sm={12}>
                            <FormControl variant="outlined" size="small" fullWidth>
                                <TextField
                                    label="Rate Type*"
                                    type="text"
                                    size="small"
                                    color="primary"
                                    style={{ width: '100%' }}
                                    InputLabelProps={{ shrink: true }}
                                    variant="outlined"
                                    select
                                    onChange={handleTDSPRateTypeChange}
                                    disabled={!tdspSelected}
                                >
                                    {rateTypes && rateTypes.sort((a, b) => a.type.localeCompare(b.type)).map(item => <MenuItem key={item.id} value={item.id}>{item.type}</MenuItem>)}
                                </TextField>
                            </FormControl>
                        </Grid>
                        <Grid style={{ marginTop: '20px' }} item sm={12}>
                            <FormControl variant="outlined" size="small" fullWidth>
                                <TextField
                                    label="Rate Class*"
                                    type="text"
                                    size="small"
                                    color="primary"
                                    style={{ width: '100%' }}
                                    InputLabelProps={{ shrink: true }}
                                    variant="outlined"
                                    select
                                    onChange={handleTDSPRateClassChange}
                                    disabled={!tdspSelected}
                                >
                                    {rateClasses && rateClasses.sort((a, b) => a.type.localeCompare(b.type)).map(item => <MenuItem key={item.id} value={item.id}>{item.type}</MenuItem>)}
                                </TextField>
                            </FormControl>
                        </Grid>
                        <Grid style={{ marginTop: '20px' }} item sm={12}>
                            <FormControl variant="outlined" size="small" fullWidth>
                                <TextField
                                    label="Rate Zone (Optional)"
                                    type="text"
                                    size="small"
                                    color="primary"
                                    style={{ width: '100%' }}
                                    InputLabelProps={{ shrink: true }}
                                    variant="outlined"
                                    select
                                    onChange={handleTDSPZoneChange}
                                    disabled={!tdspSelected}
                                >
                                    {zones && zones.sort((a, b) => a.zone.localeCompare(b.zone)).filter(item => parseInt(item.tdsp_id, 10) === newRateRequest.tdsp_id).map(item => <MenuItem key={item.id} value={item.id}>{item.zone}</MenuItem>)}
                                </TextField>
                            </FormControl>
                        </Grid>
                        <Grid style={{ marginTop: '20px' }} item sm={12}>
                            <FormControl variant="outlined" size="small" fullWidth>
                                <TextField
                                    label="Rate*"
                                    type="number"
                                    size="small"
                                    color="primary"
                                    style={{ width: '100%' }}
                                    InputLabelProps={{ shrink: true }}
                                    inputProps={{
                                        step: "0.0001",
                                        min: "0",
                                    }}
                                    variant="outlined"
                                    onChange={handleTDSPRateChange}
                                    disabled={!tdspSelected}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContentText>
            </DialogContent>
            <DialogActions style={{ marginRight: '20px', marginLeft: '20px' }}>
                <Button
                    color="secondary"
                    variant="contained"
                    style={{
                        float: "right",
                        marginRight: 5,
                        marginBottom: 10,
                    }}
                    onClick={() => {
                        setTDSPSelected(false)
                        doCreateTDSPRate(newRateRequest, 0, 100)
                        setNewRateRequest({
                            tdsp_id: 0,
                            rate_type_id: 0,
                            class_type_id: 0,
                            zone_id: 0,
                            rate: 0.0,
                            start_date: "",
                            user_id: user_id
                        });
                        setOpenDialog(false)
                    }}
                    disabled={newRateRequest.tdsp_id === 0 || newRateRequest.rate_type_id === 0 || newRateRequest.class_type_id === 0 || newRateRequest.rate <= 0.0 || newRateRequest.rate === "" || isNaN(newRateRequest.rate) || validTime === false}
                >
                    Create
                </Button>
                <Button
                    onClick={() => {
                        setTDSPSelected(false)
                        setNewRateRequest({
                            tdsp_id: 0,
                            rate_type_id: 0,
                            class_type_id: 0,
                            zone_id: 0,
                            rate: 0.0,
                            start_date: "",
                            user_id: user_id
                        });
                        setOpenDialog(false)
                    }}
                    color="primary"
                    variant="contained"
                    style={{
                        float: "right",
                        marginRight: 20,
                        marginBottom: 10,
                    }}
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}

const TDSP = (props) => {
    const [openCreateDialog, setOpenCreateDialog] = useState(false);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const pageLimit = 100
    const pageNumber = 0

    useEffect(() => {
        props.doFetchTDSPRateClasses();
        props.doFetchTDSPRateTypes();
        props.doFetchTDSPZones();
        props.doFetchTDSP();
        props.doFetchTDSPRates(pageNumber, pageLimit);
    }, []);

    return (
        <div>
            <Grid container>
                <Grid item sm={6}>
                    <h4>TDSP Rates</h4>
                </Grid>
                <Grid item sm={6}>
                    <Button
                        color="secondary"
                        variant="contained"
                        onClick={() => setOpenCreateDialog(true)}
                        style={{ float: "right", margin: "24px 0 16px" }}
                    >
                        Create
                    </Button>
                </Grid>
            </Grid>

            <RateTable
                user_id={props.authData.userID}
                tdsp={props.tdsp}
                tdsps={props.tdsp_list}
                rateTypes={props.rate_types}
                rateClasses={props.rate_classes}
                zones={props.tdsp_zones}
                openDialog={openEditDialog}
                setOpenDialog={setOpenEditDialog}
                doCreateTDSPRate={props.doCreateTDSPRate}
            />

            <CreateMode
                openDialog={openCreateDialog}
                setOpenDialog={setOpenCreateDialog}
                rateClasses={props.rate_classes}
                rateTypes={props.rate_types}
                zones={props.tdsp_zones}
                tdsps={props.tdsp_list}
                doCreateTDSPRate={props.doCreateTDSPRate}
                user_id={props.authData.userID}
            />

        </div>
    );

}

export default TDSP