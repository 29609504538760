import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router";
import ReactTable from "react-table";
import PropTypes from "prop-types";

//should be able to use data prop to access full list of meters/members
const RelatedMembersTable = ({ members, meters }) => {
  //member/meter info holder
  const [tableInfo, SetTableInfo] = useState([]);

  useEffect(() => {
    //get all table info and do any validation/formatting
    const tableInfoObjects = [];
    //add the validation/formatting stuff to a helper function which can be called both here and in MemberDetails
    if (members) {
      members.map((m) => {
        const meter = meters?.find(
          (met) =>
            met.get("memberID") === m.get("memberID") ||
            met.get("member_id") === m.get("memberID")
        );

        let meterID = "";
        let meterStartDate = "";
        let meterEndDate = "";
        let meterStatus = "";
        let memberID = "";
        let serviceAddress = "";
        let addressLineOne = "";
        let address;
        if (meter === undefined) {
          meterID = "Incomplete (no meter)";
        } else if (m.get("service_provider") === "proton_meter") {
          meterID = meter.get("meterIdentifier");
          memberID = m.get("memberID");
          meterStartDate = meter.get("start_date").substring(0, 10);
          meterEndDate = meter.get("end_date")?.substring(0, 10);
          meterStatus = meter.get("status")?.get("status");
          //get service address for proton meter           
          address = meter.get("service_address");
          addressLineOne = address.get("lineTwo")
            ? address.get("lineOne") + " " + address.get("lineTwo")
            : address.get("lineOne");
          
          
        } else if (m.get("service_provider") === "cs_meter") {
          meterID = meter.get("meter_identifier");
          memberID = m.get("memberID");
          meterStatus = meter.get("status");
          //get service address for cs meter
          address = meter.get("meta_data")?.get("address");
          addressLineOne = address.get("line2")
            ? address.get("line1") + " " + address.get("line2")
            : address.get("line1");
            
          
        } else if (m.get("service_provider") === "eci_meter") {
          meterID = meter.get("meter_identifier");
          memberID = m.get("memberID");
          meterStartDate = meter.get("start_date").substring(0, 10);
          meterEndDate = meter.get("end_date")?.substring(0, 10);
          meterStatus = meter.get("meterStatusText");
          //get service address for eci meter
          address = meter.get("serviceAddress");
          addressLineOne = address.get("line2")
            ? address.get("line1") + " " + address.get("line2")
            : address.get("line1");
        }        
        if (address) {
          serviceAddress = `${addressLineOne} 
            ${address.get("city")} 
            ${address.get("state")} 
            ${address.get("postalCode")}`;
        }
        tableInfoObjects.push({
          meterID: meterID,
          memberID: memberID,
          startDate: meterStartDate,
          endDate: meterEndDate,
          meterStatus: meterStatus,
          serviceAddress: serviceAddress,
        });
      });
    }
    //set table info once all info has been gathered
    SetTableInfo(tableInfoObjects);
  }, [members, meters]);

  const columns = () => {
    let cols = [
      {
        Header: "Meter Identifier",
        accessor: "meterID",
        Cell: (row) => (
          <div style={{ textAlign: "center" }}>
            <Link
              to={`/members/${row.original.memberID}/details`}
              activeStyle={{
                textDecoration: "none",
                color: "black",
                position: "abosolute",
                right: 0,
              }}
              component="button"
            >
              {row.original.meterID}
            </Link>
          </div>
        ),
      },
      {
        Header: "Start Date",
        accessor: "startDate",
        minWidth: 100,
        maxWidth: 150,
        width: 100,
        Cell: ({ value }) => <div style={{ textAlign: "center" }}>{value}</div>,
      },
      {
        Header: "End Date",
        accessor: "endDate",
        minWidth: 100,
        maxWidth: 150,
        width: 100,
        Cell: ({ value }) => <div style={{ textAlign: "center" }}>{value}</div>,
      },
      {
        Header: "Status",
        accessor: "meterStatus",
        Cell: ({ value }) => <div style={{ textAlign: "center" }}>{value}</div>,
      },
      {
        Header: "Service Address",
        accessor: "serviceAddress",
        Cell: ({ value }) => <div style={{ textAlign: "center" }}>{value}</div>,
      },
    ];

    return cols;
  };

  return (
    <div style={{ marginTop: 10 }}>
      {tableInfo.length > 0 ? (
        <Fragment>
          <ReactTable
            data={tableInfo}
            columns={columns()}
            className="-striped -highlight"
            defaultPageSize={10}
            defaultSortDesc
            filterable
          />
        </Fragment>
      ) : (
        <div>There are no related members to show</div>
      )}
    </div>
  );
};

RelatedMembersTable.propTypes = {
  members: PropTypes.shape({
    map: PropTypes.func,
  }),
  meters: PropTypes.shape({
    find: PropTypes.func,
  }),
};

export default RelatedMembersTable;
