import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Button from "../Button"
import CloseIcon from "@material-ui/icons/Close";
import { noselect } from "../../styles/common.css";
import { updateAccount } from "../../actions/members";
import Chip from "@material-ui/core/Chip"
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles({
  root: {
    width: "300px",
  },
  adjustmentForm: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    padding: "10px 0px 20px 0px",
    margin: "0px 10px",
    transition: "all 0.2s ease",
  },
  createNoteContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    border: "none",
  },
  textAreaContainer: {
    margin: "10px",
    height: "100px",
  },
  textArea: {
    fontFamily: "Roboto Condensed",
    fontSize: "20px",
    overflowY: "scroll",
    height: "100%",
    width: "100%",
    resize: "none",
    "&:focus": {
      outline: "none !important",
    },
  },
  menuBar: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "18px",
    fontWeight: "bold",
    padding: "10px",
    backgroundColor: "#202124",
    color: "white",
    letterSpacing: "1px",
    "& .MuiSvgIcon-root": {
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#585858",
        color: "white",
      },
    },
  },
  optionContainer: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "0px 10px",
    transition: "all 0.2s ease",
  },
  disableButton: {
    cursor: "default",
    color: "rgba(0,0,0,0.2)",
  },
  enableButton: {
    transition: "all 0.3s linear",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "15px",
    fontWeight: "bold",
    margin: "10px",
    paddingBottom: "3px",
    borderBottom: "1px solid black",
  },
  contentFullView: {
    fontSize: "15px",
    maxHeight: "100%",
    padding: "10px",
    whiteSpace: "pre-wrap",
  },
  confirmDialog: {
    textAlign: "center",
    "& .MuiTypography-root": {
      width: "100%",
    },
  },
});

const UpdateAdditionalBillingEmails = (props) => {
  const classes = useStyles();
  const { member, onAccountUpdate, closePopup } = props;
  const [accountID, setAccountID] = useState({});
  const [emails, setEmails] = useState([]);
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (email.trim() !== "" && emailRegex.test(email)) {
        if (!emails.includes(email)) {
          setEmails([...emails, email]);
          setEmail("");
          setError(false);
        }
      } else {
        setError(true);
      }
    }
  };

  const handleDelete = (emailToDelete) => {
    setEmails(emails.filter((e) => e !== emailToDelete));
  };

  const submitForm = () => {
    onAccountUpdate(accountID, "additional_billing_emails", {
      additional_billing_emails: emails,
      set_additional_billing_emails: true,
    });
    setEmails([]);
    closePopup();
  };

  useEffect(() => {
    if (member.get("additional_billing_emails")) {
      setEmails(member.get("additional_billing_emails"))
    }
    if (member.get("accountID")) {
      setAccountID(member.get("accountID"));

    }
  }, [member]);

  return (
    <div className={classes.root}>
      <Card className={classes.createNoteContainer} variant="outlined">
        <div className={`${classes.menuBar} ${noselect}`}>
          <span>Update Billing Emails</span>
          <CloseIcon onClick={() => closePopup()} />
        </div>
        <div className={classes.adjustmentForm}>
          <TextField
            label="Add Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder="Type an email and press Enter"
            error={error}
            fullWidth
          />
          <div style={{ display: "flex", flexWrap: "wrap", gap: 8, marginTop: 16 }}>
            {emails.map((email) => (
              <Chip
                key={email}
                label={email}
                onDelete={() => handleDelete(email)}
                color="primary"
              />
            ))}
          </div>
          {
            error && <div style={{ color: 'red', marginTop: '10px' }}>Please enter a valid email address.</div>
          }
        </div>
        <div className={classes.optionContainer}>
          <Button
            className={classes.enableButton}
            color="primary"
            varian="raised"
            onClick={async () => await submitForm()}
          >
            Apply
          </Button>
        </div>
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => ({
  member: state.selectedMember,
});

const mapDispatchToProps = (dispatch) => ({
  onAccountUpdate: (accountID, key, updateDated) => {
    dispatch(updateAccount(accountID, key, updateDated))
  },
  dispatch,
});

const ConnectedUpdateAdditionalBillingEmails = connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateAdditionalBillingEmails);

export default ConnectedUpdateAdditionalBillingEmails;
