import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  FormControl,
} from "@material-ui/core";
import {
  UserFields,
  AddressFields,
} from "./UserFields";

import {
  validateEmail,
  validatePhone,
  validateZip,
} from "../../util/validations";
import SelectOffer from "../Renewal/Offers";
import { ClipLoader } from "react-spinners";

const UserFieldsComponent = ({ userDetails, setUserDetails, setOffers, setProduct, setAddress, setStep, closeDialog }) => {

  const validateInput = () => {
    const phoneOk = validatePhone(userDetails.phone);
    const emailOk = validateEmail(userDetails.email);
    const zipOk = validateZip(userDetails.billing_address.postal_code);
    const userOk = userDetails.first_name && userDetails.last_name;
    const addressOk = userDetails.billing_address.line1;
    return userOk && addressOk && phoneOk && emailOk && zipOk;
  }

  return (<>
    <DialogTitle>
      Add CSA Owner
    </DialogTitle>
    <DialogContent>
      <FormControl fullWidth >
        <UserFields userDetails={userDetails} setUserDetails={setUserDetails} />
        <br />
        <Typography variant="h6">Address</Typography>
        <br />
        <AddressFields address={userDetails.billing_address} setAddress={setAddress} />
      </FormControl>
    </DialogContent >
    <DialogActions>
      <Button
        color="secondary"
        variant="contained"
        onClick={closeDialog}
      >
        Cancel
      </Button>
      <Button
        color="primary"
        variant="contained"
        disabled={!validateInput()}
        onClick={() => {
          setOffers([])
          setProduct(null)
          setStep(2)
        }}
      >
        Continue
      </Button>
    </DialogActions>
  </>)
}
const OffersComponent = ({ userDetails, offers, setOffers, setProduct, setStep, closeDialog, doCreateCSA, loading }) => {
  return (
    <>
      <DialogTitle>
        Select Offer
      </DialogTitle>
      <DialogContent>
        {
          loading ? (
            <div style={{
              display: 'flex',
              justifyContent: 'center',
              margin: '20px'
            }}>
              <ClipLoader sizeUnit="px" size={100} color="#ED0874" loading />
            </div>
          ) : offers.length === 0 ? (
            <div style={{
              display: 'flex',
              justifyContent: 'center',
              margin: '20px'
            }}>
              There are no offers available for that zip code
            </div>
          ) : (
            <div style={{
              display: 'flex',
              justifyContent: 'center'
            }}>
              <SelectOffer
                energyLevel={"2"}
                offers={offers}
                onOfferSelected={(offer) => setProduct(offer)}
                offerSelected={userDetails?.product}
              />
            </div>
          )
        }
      </DialogContent >
      <DialogActions>
        <Button
          color="secondary"
          variant="contained"
          onClick={() => {
            setOffers([])
            setProduct(null)
            setStep(1)
          }}
        >
          Go Back
        </Button>
        <Button
          color="primary"
          variant="contained"
          disabled={userDetails?.product === null}
          onClick={() => {
            doCreateCSA(userDetails);
            closeDialog();
          }}
        >
          Add CSA
        </Button>
      </DialogActions>
    </>
  )
}

const AddCSADialog = ({ closeDialog, doCreateCSA, doFetchProductOffers }) => {
  const [step, setStep] = useState(1)
  const [offers, setOffers] = useState([])
  const [loading, setLoading] = useState(false)
  const [userDetails, setUserDetails] = useState({
    company_name: "",
    email: "",
    phone: "",
    first_name: "",
    last_name: "",
    language: "en",
    billing_address: {
      line1: "",
      line2: "",
      city: "",
      state: "TX",
      postal_code: "",
    },
    product: null
  });

  const setProduct = newProduct => {
    setUserDetails({
      ...userDetails,
      product: newProduct,
    });
  }

  const setAddress = newAddress => {
    setUserDetails({
      ...userDetails,
      billing_address: newAddress,
    });
  }

  const getOffers = async zipCode => {
    setLoading(true);
    const allOffers = await doFetchProductOffers({ postal_code: zipCode });
    setOffers(allOffers?.tdsps[0].offers || []);
    setLoading(false);
  }

  useEffect(() => {
    if (step === 2) {
      getOffers(userDetails.billing_address.postal_code)
    }
  }, [step])

  return <Dialog
    open={true}
    fullWidth
    maxWidth="md"
  >
    {step === 1 && (
      <UserFieldsComponent
        userDetails={userDetails}
        setUserDetails={setUserDetails}
        setOffers={setOffers}
        setProduct={setProduct}
        setAddress={setAddress}
        setStep={setStep}
        closeDialog={closeDialog}
      />
    )}
    {step === 2 && (
      <OffersComponent
        userDetails={userDetails}
        setOffers={setOffers}
        setProduct={setProduct}
        setStep={setStep}
        doCreateCSA={doCreateCSA}
        offers={offers}
        closeDialog={closeDialog}
        loading={loading}
      />
    )}
  </Dialog >
}

export default AddCSADialog;
