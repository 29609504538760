import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import AddCSADialog from "./AddCSADialog";
import CSAView from "./CSAView";
import StatusOverlay from "../StatusOverlay";
import {
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from "@material-ui/core";
import ReactTable from "react-table";
import { createCSA, listCSAs, setSelectedCSA, fetchProductOffers } from "../../actions/csa";
import moment from "moment";

const CSA = ({
  csas,
  selected_csa,
  doSetSelectedCSA,
  doCreateCSA,
  doListCSAs,
  doFetchProductOffers,
}) => {
  const [openAddCSADialog, setOpenAddCSADialog] = useState(false);
  const [email, setEmail] = useState("")
  const [company, setCompany] = useState("")
  const [showCSA, setShowCSA] = useState(false)

  useEffect(() => {
    doListCSAs()
  }, [])

  const doSearch = () => {
    doListCSAs({ email, company })
  }

  const showSelectedCSA = (fetchedCsa) => {
    doSetSelectedCSA({ fetchedCsa })
    setShowCSA(true)
  }

  const createAndUpdateCSA = async (csa) => {
    await doCreateCSA(csa).then(x => {
      doListCSAs()
    })
  }

  return <div>

    <h4>CSAs</h4>

    <Button style={{ float: "right" }} onClick={setOpenAddCSADialog} color="primary" variant="contained">Add CSA</Button>
    {openAddCSADialog && <AddCSADialog
      open={openAddCSADialog}
      closeDialog={() => setOpenAddCSADialog(false)}
      doCreateCSA={createAndUpdateCSA}
      doFetchProductOffers={doFetchProductOffers}
    />}

    <div style={{ marginBottom: '20px' }}>
      <label>Email</label>
      <TextField value={email} onChange={(e) => setEmail(e.target.value)} style={{ marginLeft: "1em", marginRight: "1em" }} />
      <label>Company</label>
      <TextField value={company} onChange={(e) => setCompany(e.target.value)} style={{ marginLeft: "1em", marginRight: "1em" }} />
      <Button color="primary" variant="contained" onClick={doSearch}>Search</Button>
    </div>

    {showCSA && <Dialog open={true} fullWidth maxWidth="lg">
      <DialogTitle>CSA Details</DialogTitle>
      <DialogContent><CSAView parentCSA={selected_csa} /></DialogContent>
      <DialogActions><Button color="primary" variant="contained" onClick={() => setShowCSA(false)}>Close</Button></DialogActions>
    </Dialog>
    }
    <ReactTable
      data={csas}
      columns={[
        {
          Header: "ID",
          accessor: "id",
          Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>
        },
        {
          Header: "Member ID",
          accessor: "member_id",
          Cell: (row) => <div style={{ textAlign: "center" }}><a href={`/members/${row.value}/details`}>{row.value}</a></div>
        },
        {
          Header: "Company",
          id: "Company",
          accessor: "company_name",
          Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>
        },
        {
          Header: "Email",
          accessor: "email",
          Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>
        },
        {
          Header: "Phone",
          accessor: "phone_number",
          Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>
        },
        {
          Header: "Created",
          accessor: "created",
          Cell: (row) => {
            return <div style={{ textAlign: "center" }}>{moment(row.value).local().format("YYYY/MM/DD")}</div>;
          }
        },
        {
          Header: "Actions",
          accessor: "actions",
          width: 75,
          resizable: false,
          sortable: false,
          style: { textAlign: "center" },
          Cell: (row) => {
            return <Button color="primary" variant="contained" onClick={() => showSelectedCSA(row.original)}>View</Button>
          }
        }
      ]}
      defaultPageSize={10}
    />
    <StatusOverlay />
  </div>
}

const mapStateToProps = (state) => {
  return {
    csas: state.csa.csas,
    selected_csa: state.csa.selected_csa,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    doCreateCSA: (csa) => dispatch(createCSA(csa)),
    doListCSAs: (params) => dispatch(listCSAs(params)),
    doSetSelectedCSA: (query) => dispatch(setSelectedCSA(query)),
    doFetchProductOffers: (params) => dispatch(fetchProductOffers(params)),
    dispatch,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CSA);