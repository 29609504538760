import React from "react";
import { Route, Redirect } from "react-router";
import { routerActions } from "react-router-redux";
import { UserAuthWrapper } from "redux-auth-wrapper";
import { App, Member, Guest } from "./components";

import MemberDetails from "./containers/MemberDetails";
import MemberPaymentContainer from "./containers/MemberPaymentContainer";
import MemberSavings from "./containers/MemberSavings";
import DnpRnpHistory from "./containers/DnpRnpHistory";
import GuestDetails from "./containers/GuestDetails";
import MeterTransactions from "./containers/MeterTransactions";
import MeterTransactionsHome from "./containers/MeterTransactionsHome";
import Enrollments from "./containers/Enrollments";

import Meter from "./containers/Meter";
import Usage from "./containers/Usage";
import Members from "./containers/Members";
import Login from "./containers/Login";
import MeterUpdate from "./containers/MeterUpdate";
import Dnp from "./containers/Dnp";
import DnpRnp from "./containers/DnpRnp";

import UserNotesContainer from "./containers/UserNotes";
import ReportsContainer from "./containers/Reports";
import CommunitySolarContainer from "./containers/CommunitySolar";
import JobNotificationsContainer from "./containers/JobNotifications";
import BatchContainer from "./containers/Batches";
import NotificationsContainer from "./containers/Notifications";
import SettingsContainer from "./containers/Settings";
import MemberBillingContainer from "./containers/Billings";
import StatementApprovalContainer from "./containers/StatementApproval";
import MemberStatementsContainer from "./containers/Statements";
import IterableContainer from "./containers/Iterable";
import PaymentContainer from "./containers/Payments";
import MemberTOSContainer from "./containers/TransferOfService";
import MemberProductContainer from "./containers/MemberRenewal";
import MemberNotificationsContainer from "./containers/MemberNotifications";
import MemberDocumentsContainer from "./containers/MemberDocuments";
import ReferralsContainer from "./containers/Referrals";
import MemberReferralsContainer from "./containers/MemberReferralsContainer";
import CSA from "./components/CSA";
import { connect } from "react-redux";
import MemberCSAContainer from "./containers/MemberCSAContainer";
import PromoContainer from "./containers/Promo";
import { HAS_CSA_SERVICE } from "./util/flags";
import SalesChannelContainer from "./containers/SalesChannel";
import TDSPContainer from "./containers/TDSP"
import MemberDevicesContainer from "./containers/MemberDevices";
import ProfileContainer from "./containers/ChangePassword";
import RelatedMembersContainer from "./containers/RelatedMembers";

const UserIsAuthenticated = (permission) =>
  UserAuthWrapper({
    authSelector: (state) => state.user, // how to get the user state
    redirectAction: routerActions.replace, // the redux action to dispatch for redirect
    wrapperDisplayName: "UserIsAuthenticated", // a nice name for this auth check
    predicate: (user) => {
      if (permission) {
        return user.userID && user.permissions[permission];
      } else {
        return user.userID;
      }
    },
  });

const AdminManagementWrapper = (WrappedComponent) => {
  const CustomWrapper = (props) => {
    const { isUserManager } = props;
    if (isUserManager) {
      return <WrappedComponent {...props} />;
    } else {
      window.location.replace("/members");
      return null;
    }
  };

  const mapStateToProps = (state) => {
    return { isUserManager: state.user.permissions.isUserManager };
  };

  return connect(mapStateToProps)(CustomWrapper);
};

export default (
  <div>
    <Route component={App}>
      <Route path="login" component={Login} />
      <Route path="members" component={UserIsAuthenticated()(Members)} />
      <Route
        exact
        path="members/:memberId"
        component={UserIsAuthenticated()(Member)}
      >
        <Route path="usage" component={UserIsAuthenticated()(Usage)} />
        <Route
          path="details"
          component={UserIsAuthenticated()(MemberDetails)}
        />
        <Route
          path="billing"
          component={UserIsAuthenticated()(MemberBillingContainer)}
        />
        <Route
          path="statements"
          component={UserIsAuthenticated()(MemberStatementsContainer)}
        />
        <Route
          path="payment"
          component={UserIsAuthenticated()(MemberPaymentContainer)}
        />
        <Route path="dnprnp" component={UserIsAuthenticated()(DnpRnpHistory)} />
        <Route
          path="metertransactions"
          component={UserIsAuthenticated()(MeterTransactions)}
        />
        <Route
          path="transferofservice"
          component={UserIsAuthenticated()(MemberTOSContainer)}
        />
        <Route
          path="renewal"
          component={UserIsAuthenticated()(MemberProductContainer)}
        />
        <Route
          path="product_change"
          component={UserIsAuthenticated()(MemberProductContainer)}
        />
        <Route
          path="related_members"
          component={UserIsAuthenticated()(RelatedMembersContainer)}
        />
        <Route
          path="notes"
          component={UserIsAuthenticated()(UserNotesContainer)}
        />
        {process.env.REACT_APP_ENABLE_ITERABLE === "true" && (
          <Route
            path="iterable"
            component={UserIsAuthenticated()(IterableContainer)}
          />
        )}
        <Route
          path="notifications"
          component={UserIsAuthenticated()(MemberNotificationsContainer)}
        />
        {process.env.REACT_APP_BRAND_INSTANCE_NAME === "think" && (
          <Route
            path="promo"
            component={UserIsAuthenticated()(PromoContainer)}
          />
        )}
        {HAS_CSA_SERVICE && (
          <Route
            path="csa"
            component={UserIsAuthenticated()(MemberCSAContainer)}
          />
        )}
        <Route
          path="documents"
          component={UserIsAuthenticated()(MemberDocumentsContainer)}
        />
        {process.env.REACT_APP_ENABLE_REFERRALS === "true" && (
          <Route
            path="referrals"
            component={UserIsAuthenticated()(MemberReferralsContainer)}
          />
        )}
        {process.env.REACT_APP_ENABLE_DEVICES === "true" && (
          <Route
            path="devices"
            component={UserIsAuthenticated()(MemberDevicesContainer)}
          />
        )}
      </Route>
      <Route path="dnprnp" component={DnpRnp}>
        <Route path="dnpqueue" component={UserIsAuthenticated()(Dnp)} />
        <Route
          path="dnprnptransactions"
          component={UserIsAuthenticated()(DnpRnpHistory)}
        />
      </Route>
      <Route
        path="metertransactions"
        component={UserIsAuthenticated()(MeterTransactionsHome)}
      >
        <Route
          path="pending"
          component={UserIsAuthenticated()(MeterTransactions)}
        />
        <Route
          path="error"
          component={UserIsAuthenticated()(MeterTransactions)}
        />
        <Route
          path="held"
          component={UserIsAuthenticated()(MeterTransactions)}
        />
        <Route
          path="history"
          component={UserIsAuthenticated()(MeterTransactions)}
        />
      </Route>
      <Route
        path="statementapproval"
        component={UserIsAuthenticated()(StatementApprovalContainer)}
      />
      <Route
        path="payment"
        component={UserIsAuthenticated()(PaymentContainer)}
      />
      <Route
        path="reports"
        component={UserIsAuthenticated()(ReportsContainer)}
      />
      {process.env.REACT_APP_BRAND_INSTANCE_NAME === "think" && (
        <Route
          path="community_solar"
          component={UserIsAuthenticated()(CommunitySolarContainer)}
        />
      )}
      <Route
        path="jobNotifications"
        component={UserIsAuthenticated()(JobNotificationsContainer)}
      />
      <Route path="batch" component={UserIsAuthenticated()(BatchContainer)} />
      <Route
        path="notifications"
        component={UserIsAuthenticated()(NotificationsContainer)}
      />
      {HAS_CSA_SERVICE && (
        <Route path="csa" component={UserIsAuthenticated()(CSA)} />
      )}
      {process.env.REACT_APP_BRAND_INSTANCE_NAME === "think" && (
        <Route path="promo" component={UserIsAuthenticated()(PromoContainer)} />
      )}
      <Route
        path="management"
        component={AdminManagementWrapper(SettingsContainer)}
      />
      <Route
        exact
        path="guest/:guestUserId"
        component={UserIsAuthenticated()(Guest)}
      >
        <Route path="usage" component={UserIsAuthenticated()(Usage)} />
        <Route path="details" component={UserIsAuthenticated()(GuestDetails)} />
        <Route
          path="savings"
          component={UserIsAuthenticated()(MemberSavings)}
        />
      </Route>
      <Route path="meters" component={UserIsAuthenticated()(Meter)} />
      <Route
        path="enrollments"
        component={UserIsAuthenticated()(Enrollments)}
      />
      <Route
        path="meters/:meterIdentifier"
        component={UserIsAuthenticated()(MeterUpdate)}
      />
      {process.env.REACT_APP_ENABLE_REFERRALS === "true" && (
        <Route
          path="referrals"
          component={UserIsAuthenticated("isReferralCreditsManager")(
            ReferralsContainer
          )}
        />
      )}
      <Route
        path="sales_channel"
        component={UserIsAuthenticated()(SalesChannelContainer)}
      />
      <Route
        path="tdsp_rates"
        component={UserIsAuthenticated()(TDSPContainer)}
      />
      <Route
        path="profile/change_password"
        component={UserIsAuthenticated()(ProfileContainer)}
      />
    </Route>
    <Redirect from="/" to="/members" />
  </div>
);
