import styled from "styled-components";
import mq from "../../../styles/mq";

export const ContainerRateCards = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 5px;

  margin-top: 15px;
  align-items: center;
  ${mq.tablet`
        flex-direction: column;
    `};
`;

export const RateCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  box-sizing: border-box !important;
  align-items: center;
  background: ${(props) => (props.selected ? "#eeeeee" : "white")};
  margin-top: 15px;
  margin-bottom: 10px;
  border: 1px solid lightgrey;
  border-radius: 4px;
  box-shadow: 2px 4px 4px 2px lightgrey;

  ${mq.tablet`
    width: 650px;
  `}
`;

export const OfferBasics = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;

  align-items: center;
  ${mq.tablet`
    width: 650px;
    flex-direction: row;
    height: 200px;
  `}
`;

export const OfferDetails = styled.div`
  box-sizing: border-box;
  display: ${(props) => (props.open ? "flex" : "none")};
  flex-direction: column;
  transition: all 6.1s;
  padding: 20px;
  width: 100%;
  border-top: 1px solid lightgray;
  opacity: ${(props) => (props.open ? "1" : "0")};
  max-height: ${(props) => (props.open ? "100%" : "0")};
`;

export const RateInfoSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: rgb(255, 64, 129);
  padding: 15px;
  box-sizing: border-box;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${mq.tablet`
    height: 100%;
    width: 35%;
  `};
`;
export const RateDescriptionSection = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  padding: 15px;
  ${mq.tablet`
    height: 100%;
    width: 35%;
  `}
`;
export const RateActionSection = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  padding: 15px;

  ${mq.tablet`
    height: 100%;
    width: 35%;
    border-left: 1px solid white;
  `}
`;

export const EnergyUnitSection = styled.div`
  display: flex;
  height: 100%;
  width: 60%;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 5px;
  flex-direction: column;
`;
export const TitleRateCard = styled.div``;

export const CustomTable = styled.table`
  border-collapse: collapse !important;
  &&& {
    table,
    th,
    td {
      border: 1px solid grey !important;
      border-collapse: collapse !important;
    }
    th,
    td,
    tr {
      padding: 5px;
    }
    th {
      text-align: left;
    }
    table {
      width: 100%;
    }
  }
`;
