import React, { Fragment, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router";
import { navigation } from "../styles/navigation.css";
import { logout } from "../actions/user";
import { connect } from "react-redux";
import Maintenance from "./Maintenance";
import { HAS_CSA_SERVICE } from "../util/flags";
import { useIdleTimer } from 'react-idle-timer';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "./Button";
import ProfileSettings from "./ProfileSettings";

const App = ({ children, user }) => {
  const idleTimerRef = useRef(null)
  const [showDialog, setShowDialog] = useState(false)
  const [logoutTimeout, setLogoutTimeout] = useState(null)
  const oneHour = 60 * 60 * 1000
  const {
    isReferralCreditsManager,
    isUserManager,
    isFileUploadManager,
    isLMIApproverManager,
  } = user.permissions;

  const handleLogout = shouldLogout => {
    if (shouldLogout) {
      logout()
    }
    if (logoutTimeout) {
      clearTimeout(logoutTimeout)
      setLogoutTimeout(null)
    }
    setShowDialog(false)
  }

  const handleDialog = () => {
    if (user?.userID !== null) {
      setShowDialog(true)
      setLogoutTimeout(setTimeout(() => handleLogout(true), 2 * 60 * 1000))
    }
  }

  useIdleTimer({
    ref: idleTimerRef,
    timeout: oneHour,
    onIdle: () => handleDialog(),
    debounce: 500,
  });

  return (
    <div>
      {process.env.REACT_APP_MAINTENANCE_MODE !== "true" && (
        <div>
          <header className={navigation}>
            <div>
              {/* https://goo.gl/gtyF9S - nav component example to remove inline activestyle below */}
              <Link
                to="/members"
                activeStyle={{ textDecoration: "none", color: "black" }}
              >
                Members
              </Link>{" "}
              <Link
                to="/meters"
                activeStyle={{ textDecoration: "none", color: "black" }}
              >
                Meter Lookup
              </Link>{" "}
              <Link
                to="/enrollments"
                activeStyle={{ textDecoration: "none", color: "black" }}
              >
                Enrollments
              </Link>{" "}
              <Link
                to="/dnprnp/dnpqueue"
                activeStyle={{ textDecoration: "none", color: "black" }}
              >
                Disconnect Notices
              </Link>{" "}
              <Link
                to="/metertransactions/pending"
                activeStyle={{ textDecoration: "none", color: "black" }}
              >
                Transactions
              </Link>{" "}
              <Link
                to="/statementapproval"
                activeStyle={{ textDecoration: "none", color: "black" }}
              >
                Statement Approval
              </Link>{" "}
              <Link
                to="/payment"
                activeStyle={{ textDecoration: "none", color: "black" }}
              >
                Payment
              </Link>{" "}
              {HAS_CSA_SERVICE && (
                <>
                  <Link
                    to="/csa"
                    activeStyle={{ textDecoration: "none", color: "black" }}
                  >
                    CSA
                  </Link>{" "}
                </>
              )}
              {process.env.REACT_APP_BRAND_INSTANCE_NAME === "think" && (
                <>
                  <Link
                    to="/promo"
                    activeStyle={{ textDecoration: "none", color: "black" }}
                  >
                    Promo
                  </Link>{" "}
                </>
              )}
              <Link
                to="/reports"
                activeStyle={{ textDecoration: "none", color: "black" }}
              >
                Reports
              </Link>{" "}
              <Link
                to="/jobNotifications"
                activeStyle={{ textDecoration: "none", color: "black" }}
              >
                Notification Jobs
              </Link>{" "}
              <Link
                to="/notifications"
                activeStyle={{ textDecoration: "none", color: "black" }}
              >
                Notifications
              </Link>{" "}
              <Link
                to="/sales_channel"
                activeStyle={{ textDecoration: "none", color: "black" }}
              >
                Sales Channel
              </Link>{" "}
              <Link
                to="/tdsp_rates"
                activeStyle={{ textDecoration: "none", color: "black" }}
              >
                TDSP Rates
              </Link>{" "}
              {isLMIApproverManager &&
                process.env.REACT_APP_BRAND_INSTANCE_NAME === "think" && (
                  <>
                    <Link
                      to="/community_solar"
                      activeStyle={{ textDecoration: "none", color: "black" }}
                    >
                      Community Solar
                    </Link>{" "}
                  </>
                )}
              {isFileUploadManager && (
                <Fragment>
                  <Link
                    to="/batch"
                    activeStyle={{ textDecoration: "none", color: "black" }}
                  >
                    Batch Imports
                  </Link>{" "}
                </Fragment>
              )}
              {process.env.REACT_APP_ENABLE_REFERRALS === "true" &&
                isReferralCreditsManager && (
                  <Link
                    to="/referrals"
                    activeStyle={{ textDecoration: "none", color: "black" }}
                  >
                    Referrals
                  </Link>
                )}{" "}
              {isUserManager && (
                <Fragment>
                  <Link
                    to="/management"
                    activeStyle={{ textDecoration: "none", color: "black" }}
                  >
                    Settings
                  </Link>{" "}
                </Fragment>
              )}
            </div>
            <ProfileSettings />
          </header>
          {children}
        </div>
      )}
      {process.env.REACT_APP_MAINTENANCE_MODE === "true" && <Maintenance />}
      <Dialog
        onClose={async () => handleLogout(false)}
        open={showDialog}
      >
        <DialogTitle>
          Session about to expire
        </DialogTitle>
        <DialogContent dividers>
          <div>
            For security, your session is about to expire. Do you want to stay connected?
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={async () => handleLogout(false)} color="primary">
            Stay connected
          </Button>
          <Button autoFocus onClick={async () => handleLogout(true)} color="secondary">
            Log out
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

App.propTypes = {
  children: PropTypes.object.isRequired,
  user: PropTypes.object,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, null)(App);
