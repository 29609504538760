import * as constants from "../../constants";
import fetch from "../util/api-ajax";
import * as status from "./pageStatus";

const tdsp = {
  tdsp_rate_list: [],
  total: 0
}

function geTDSPRatesSuccess(payload) {
  return {
    type: constants.LIST_TDSP_RATES_SUCCESS,
    payload,
  };
}

function getTDSPRateClassesSuccess(payload) {
  return {
    type: constants.LIST_TDSP_RATE_CLASSES_SUCCESS,
    payload,
  };
}

function getTDSPRateTypesSuccess(payload) {
  return {
    type: constants.LIST_TDSP_RATE_TYPES_SUCCESS,
    payload,
  };
}

function getTDSPZonesSuccess(payload) {
  return{
    type: constants.LIST_TDSP_ZONES_SUCCESS,
    payload,
  };
}

function getTDSPSuccess(payload) {
  return {
    type: constants.TDSP_SUCCESS,
    payload,
  };
}

function createTDSPRateSuccess(payload) {
  return {
    type: constants.CREATE_TDSP_RATE_SUCCESS,
    payload,
  };
}

export function getTDSPRates(pageNumber, pageLimit) {
  return async (dispatch) => {
    try {
      const res = await fetch(constants.LIST_TDSP_RATES_URL, {
        "limit": pageLimit.pageLimit, 
        "page": pageNumber.pageNumber
      });

      tdsp.total = res.total;
      tdsp.tdsp_rate_list = [...res.tdsp_rate_list];

      if (res.hasNext === true) {
        let hasNext = res.hasNext;
        let currentPage = pageNumber.pageNumber + 1;

        while (hasNext === true) {
          const new_res = await fetch(constants.LIST_TDSP_RATES_URL, {
            "limit": pageLimit.pageLimit, 
            "page": currentPage
          });

          tdsp.tdsp_rate_list = [...tdsp.tdsp_rate_list, ...new_res.tdsp_rate_list];

          hasNext = new_res.hasNext;
          if (hasNext) currentPage += 1;
        }
      }

      dispatch(geTDSPRatesSuccess(tdsp));
    } catch (error) {
      dispatch(status.setErrorMessage(error.message));
    } finally {
      dispatch(status.finishProcessing());
    }
  };
}

export function getTDSPRateClasses() {
  return async (dispatch) => {
    try{
      const res = await fetch(constants.LIST_TDSP_RATE_CLASSES_URL, {})

      dispatch(getTDSPRateClassesSuccess(res))

    } catch (error) {
      dispatch(status.setErrorMessage(error.message));
    } finally {
      dispatch(status.finishProcessing());
    }
  }
}

export function getTDSPRateTypes() {
  return async (dispatch) => {
    try{
      const res = await fetch(constants.LIST_TDSP_RATE_TYPES_URL, {})

      dispatch(getTDSPRateTypesSuccess(res))

    } catch (error) {
      dispatch(status.setErrorMessage(error.message));
    } finally {
      dispatch(status.finishProcessing());
    }
  }
}

export function getTDSPZones() {
  return async (dispatch) => {
    try{
      const res = await fetch(constants.LIST_TDSP_ZONES_URL, {})

      dispatch(getTDSPZonesSuccess(res))

    } catch (error) {
      dispatch(status.setErrorMessage(error.message));
    } finally {
      dispatch(status.finishProcessing());
    }
  }
}

export function getTDSP() {
  return async (dispatch) => {
    try{
      const res = await fetch(constants.TDSP_URL, {})

      dispatch(getTDSPSuccess(res))

    }  catch (error) {
      dispatch(status.setErrorMessage(error.message));
    } finally {
      dispatch(status.finishProcessing());
    }
  }
}

export function createTDSPRate (CreateNewRate, pageNumber, pageLimit) {
  return async (dispatch) => {
    try {

      const res = await fetch(constants.CREATE_TDSP_RATE, {
        "tdsp_id": CreateNewRate.tdsp_id,
        "rate_type_id": CreateNewRate.rate_type_id,
        "class_type_id": CreateNewRate.class_type_id,
        "zone_id": CreateNewRate.zone_id,
        "rate": CreateNewRate.rate,
        "start_date": CreateNewRate.start_date,
        "user_id": CreateNewRate.user_id,
      })

      dispatch(createTDSPRateSuccess(res))

      dispatch(getTDSPRates(pageNumber, pageLimit))

    } catch (error) {
      dispatch(status.setErrorMessage(error.message));
    } finally {
      dispatch(status.finishProcessing());
    }
  }
}