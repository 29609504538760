import fetch from "../util/api-ajax";
import * as constants from "../../constants";
import * as status from "./pageStatus";
import moment from "moment";
import { HAS_CSA_SERVICE } from "../util/flags";

// Get user and CSA information to populate CSAView
export function setSelectedCSA({ fetchedCsa, userID, memberID, allowFailure = false }) {
  return async (dispatch) => {
    if (!HAS_CSA_SERVICE) {
      // Exit early if CSA service is not enabled (used in member initialization to avoid unnecessary API calls)
      return;
    }

    dispatch(status.startProcessing());

    try {
      let csa;
      let user;
      if (fetchedCsa) {
        csa = fetchedCsa;
        user = await fetch(constants.GET_USER_URL, { userID: csa?.user_id });
      } else {
        try {
          const csa_info = await fetch(constants.CSA_GET_CSA_URL, { user_id: parseInt(userID), member_id: memberID });
          csa = csa_info?.csa;
          // Use the returned user_id just in case userID wasn't provided
          const csa_user_id = csa?.user_id;
          user = await fetch(constants.GET_USER_URL, { userID: csa_user_id });
        } catch (error) {
          // If we don't care about fetch failures, just return empty objects on error
          if (!allowFailure) {
            throw error;
          }
          csa = {};
          user = {};
        }
      }

      dispatch(receiveCSA({ csa }));
      if (user?.billing_address && typeof user.billing_address === "string") {
        user.billing_address = JSON.parse(user.billing_address);
      }
      dispatch(receiveUser({ user }));
    } catch (error) {
      dispatch(status.setErrorMessage(error.message));
    } finally {
      dispatch(status.finishProcessing());
    }
  };
}

function receiveCSA(csa) {
  return {
    type: constants.CSA_RECEIVE_CSA,
    payload: csa,
  };
}

function receiveUser(user) {
  return {
    type: constants.CSA_RECEIVE_USER,
    payload: user,
  };
}

// Create a new parent CSA
export function createCSA(data) {
  return async (dispatch) => {
    dispatch(status.startProcessing());
    try {
      const response = await fetch(constants.CSA_CREATE_CSA_URL, data);
      dispatch(receiveCSA(response));
      dispatch(status.setSuccessMessage("CSA Created"));
    } catch (error) {
      dispatch(status.setErrorMessage(error.message));
    } finally {
      dispatch(status.finishProcessing());
    }
  };
}


export function listCSAMembers({ csa_id, email }) {
  return async (dispatch) => {
    dispatch(status.startProcessing());
    try {
      const response = await fetch(constants.CSA_LIST_MEMBERS_URL, { csa_id });
      const csaMembers = response.csa_members;

      // Append enrollments
      const enrollResponse = await fetch(constants.ENROLLMENT_SEARCH_URL, { csa_id, email });
      if (enrollResponse?.list) {
        const enrollments = enrollResponse.list;
        for (let i = 0; i < enrollments.length; i++) {
          const member = csaMembers.find((member) => member.member_id === enrollments[i].member_id);
          if (member) {
            member.enrollment = enrollments[i];
          } else {
            csaMembers.push({
              enrollment: enrollments[i],
              meter_type: enrollments[i].enroll_mode == "csa" ? "tenant" : "common",
              member_id: enrollments[i].member_id,
              meter: {
                meter_identifier: enrollments[i].meter_identifier,
                status: "enrollment pending",
                on_csa: "",
              }
            });
          }
        }
      }
      dispatch(receiveCSAMembers(csaMembers));
    } catch (error) {
      dispatch(status.setErrorMessage(error.message));
    } finally {
      dispatch(status.finishProcessing());
    }
  };
}

function receiveCSAMembers(members) {
  return {
    type: constants.CSA_RECEIVE_MEMBERS,
    payload: { members },
  };
}

// Returns a list of tdsp product offers (not saved to state)
export function fetchProductOffers({ locale, memberID, postal_code }) {
  return async (dispatch) => {
    dispatch(status.startProcessing());
    let response;
    try {
      response = await fetch(constants.GET_MEMBER_PRODUCT_CHANGE_OFFERS, { locale, memberID, postal_code, promoCode: "CSA" });
    } catch (error) {
      dispatch(status.setErrorMessage(error.message));
    } finally {
      dispatch(status.finishProcessing());
    }
    return response;
  };
}

// Returns meter information for an ESI ID (not saved to state)
export function findMeter(esi_id) {
  return async (dispatch) => {
    dispatch(status.startProcessing());
    let meter;
    try {
      const response = await fetch(constants.METER_SEARCH, { esi_id });
      if (response?.meters?.length) {
        meter = response.meters[0];
      } else {
        dispatch(status.setErrorMessage("Meter not found for ESI ID"));
      }
    } catch (error) {
      dispatch(status.setErrorMessage(error.message));
    } finally {
      dispatch(status.finishProcessing());
    }
    return meter;
  };
}

export function submitEnrollment(props) {
  return async (dispatch) => {
    dispatch(status.startProcessing());
    try {
      const userInfo = props.userInfo;
      const csaInfo = props.csaInfo;

      const product = props.product;
      const meterInfo = props.meterInfo;

      const start_date = moment(meterInfo.requestedStartDate).format("YYYY-MM-DD");

      const payload = {
        formData: {
          firstName: userInfo.first_name,
          lastName: userInfo.last_name,
          language: userInfo.language,
          billingAddress: userInfo.billing_address,
          birthdate: moment(userInfo.birthday).utc().format("YYYY-MM-DD"),
          requestedStartDate: meterInfo.firstAvailableDate ? undefined : start_date,
          enrollMode: meterInfo.enrollMode,
          firstAvailableDate: meterInfo.firstAvailableDate,
          address: meterInfo.address,
          meterUID: meterInfo.meterUID,
          promoCode: "CSA",
          product: !product ? {} : {
            ...product,
            term_months: product.term_months.toString(),
            max_deposit: product.max_deposit.toString(),
            start_date,
          },
          memberAgreement: true,
          sendEnrollmentCompleteNotification: false,
          sendDepositNotification: false,
          enrollmentCompletionDate: new Date().toISOString(),
          csaID: csaInfo.id,
          company_name: csaInfo.company_name,
        },
        enrollmentType: "add_meter",
        createdBy: localStorage.getItem("email"),
        email: userInfo.email,
        phone: userInfo.phone,
        tdsp_name: meterInfo.tdsp_name,
      };

      const res = await fetch(constants.ENROLL_RENEWAL_OFFER, payload);
      if (res && res.enrollmentID) {
        const deposit = await fetch(constants.ENROLL_GET_DEPOSIT_URL, { enrollmentID: res.enrollmentID })
        if (deposit) {
          const success = await fetch(constants.ENROLLMENT_RESUBMIT_URL, {
            enrollmentID: res.enrollmentID,
            is_waived_deposit: true,
            is_verified: true,
            is_identity_verified: true,
            is_completed: true,
            note: "CSA Meter"
          })

          if (success) {
            dispatch(status.setSuccessMessage("Enrollment submitted"));
          }
        }
      }
    } catch (error) {
      dispatch(status.setErrorMessage(error.message));
    } finally {
      dispatch(status.finishProcessing());
    }
  };
}

async function getUsers(params) {
  const user_info = await fetch(constants.USERS_MANAGEMENT_GET_USERS, params);
  if (user_info.users && user_info.users.length > 0) {
    return user_info.users;
  }
  return null;
}

// List all CSAs. If given an email only return CSA for that user.
export function listCSAs(params) {
  return async (dispatch) => {
    dispatch(status.startProcessing());
    try {
      const filter = {}
      if (params?.email) {
        const users = await getUsers({ search: params.email });
        if (users) {
          filter['user_id'] = { values: users.map(x => x.id) };
        }
      }
      if (params?.company) {
        filter['company'] = params.company
      }
      const response = await fetch(constants.CSA_LIST_CSA_URL, filter);
      dispatch(receiveCSAs(response.csas || []));
    } catch (error) {
      dispatch(status.setErrorMessage(error.message));
    } finally {
      dispatch(status.finishProcessing());
    }
  };
}

function receiveCSAs(csas) {
  return {
    type: constants.CSA_LIST_CSA,
    payload: { csas },
  };
}
