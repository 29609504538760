import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Select,
  MenuItem,
  Grid,
  Checkbox,
  Typography,
  FormControl,
  InputLabel,
  FormControlLabel,
} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import {
  UserFields,
  AddressFields,
} from "./UserFields";


const AddMeterDialog = ({ closeDialog, parentCSA, userInfo, doFetchProductOffers, doFindMeter, submitMemberEnrollment }) => {
  const [meterType, setMeterType] = useState("tenant");
  const [esiID, setEsiID] = useState("");
  const [address, setAddress] = useState(null);
  const displayUser = { company_name: parentCSA?.company_name, ...userInfo };

  function submitEnrollment() {
    const enrollMode = meterType == "common" ? meterInfo.enrollMode : "csa";
    submitMemberEnrollment({
      meterInfo: { ...meterInfo, address, enrollMode },
    });
  }

  const [meterInfo, setMeterInfo] = useState({
    meterUID: "",
    enrollMode: "switch",
    enrollmentType: "add_meter",
    requestedStartDate: new Date,
    firstAvailableDate: false,
    tdsp_name: "",
  });

  function updateMeter(event) {
    setMeter({ [event.target.name]: event.target.value });
  }

  function setMeter(props) {
    if (props.enrollMode == "movein") {
      props.firstAvailableDate = false;
    }
    setMeterInfo({
      ...meterInfo,
      ...props,
    })
  }

  async function onSetESI(e) {
    let newAddr = null;
    let newMeterProps = {
      tdsp_name: "",
      meterUID: esiID,
    };
    const meter = await doFindMeter(esiID);
    if (meter) {
      newAddr = meter.address;
      const allOffers = await doFetchProductOffers({ memberID: parentCSA.member_id, postal_code: meter.address.postal_code });
      const meterOffers = allOffers.tdsps?.find((tdsp) => tdsp.duns_number === meter.duns);
      if (meterOffers) {
        newMeterProps.tdsp_name = meterOffers.name;
      }
    }
    setMeter(newMeterProps);
    setAddress(newAddr);
  }

  function validateInput() {
    return address && userInfo;
  }

  return <Dialog
    open={true}
    contentlabel="Add Meter"
    fullWidth
    maxWidth="md"
  >
    <DialogTitle>
      Add Meter to CSA
    </DialogTitle>
    <DialogContent>
      <Grid container spacing={2}>
        <Grid item sm={12}>
          <div>
            <TextField onChange={(e) => setEsiID(e.target.value)} label="ESI ID" value={esiID} variant="outlined" size="small" style={{ minWidth: "250px" }} />
            <Button
              variant="contained"
              color="primary"
              onClick={onSetESI}
              disabled={!esiID}
            >
              Set ESI ID
            </Button>
          </div>
        </Grid>
        <Grid item sm={6}>
          <FormControl variant="outlined" size="small" fullWidth>
            <InputLabel id="meter-type">Meter Type</InputLabel>
            <Select onChange={(e) => setMeterType(e.target.value)} value={meterType} labelId="meter-type" label="Meter Type">
              <MenuItem value="common">Common</MenuItem>
              <MenuItem value="tenant">Tenant</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {meterType == "common" && <>
          <Grid item sm={6}>
            <FormControl variant="outlined" size="small" fullWidth>
              <InputLabel id="enroll-mode">Enrollment Type</InputLabel>
              <Select name="enrollMode" onChange={updateMeter} value={meterInfo.enrollMode} labelId="enroll-mode" label="Enrollment Type">
                <MenuItem value="switch">Switch</MenuItem>
                <MenuItem value="movein">Move In</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item sm={6}>
            {meterInfo.enrollMode == "switch" && <>
              <FormControlLabel
                control={<Checkbox
                  checked={meterInfo.firstAvailableDate}
                  onChange={(e) => setMeter({ firstAvailableDate: e.target.checked })}
                />}
                label="First Available Date"
              />
            </>}
          </Grid>
          <Grid item sm={6}>
            {meterInfo.firstAvailableDate || <>
              <DatePicker
                value={meterInfo.requestedStartDate}
                onChange={(date) => setMeter({ requestedStartDate: date })}
                format="yyyy-MM-dd"
                label="Start Date"
                size="small"
                autoOk
                disablePast
              />
            </>}
          </Grid>
        </>}
        <Grid item xs={12}>
          <Typography variant="h6">User Information</Typography>
        </Grid>
        <Grid item xs={12}>
          {userInfo && <UserFields userDetails={displayUser} disabled />}
        </Grid>
        {address && <>
          <Grid item xs={12}>
            <Typography variant="h6">Meter Address</Typography>
          </Grid>
          <Grid item xs={12}>
            <AddressFields address={address} disabled />
          </Grid>
        </>}
      </Grid>
    </DialogContent>
    <DialogActions>
      <Button
        color="secondary"
        onClick={closeDialog}
      >
        Cancel
      </Button>
      <Button
        color="primary"
        disabled={!validateInput()}
        onClick={() => {
          submitEnrollment();
          closeDialog();
        }}
      >
        Add Meter
      </Button>
    </DialogActions>
  </Dialog>
};

export default AddMeterDialog;
