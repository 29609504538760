import React from "react";
import { connect } from "react-redux";
import TDSP from "../components/TDSP";
import selectTDSPContainer from "../selectors/tdsp";
import { createTDSPRate, getTDSP, getTDSPRateClasses, getTDSPRates, getTDSPRateTypes, getTDSPZones } from "../actions/tdsp";

const TDSPContainer = (props) => <TDSP {...props} />;

const mapStateToProps = selectTDSPContainer();

const mapDispatchToProps = (dispatch) => ({
    doFetchTDSPRates: (pageNumber, pageLimit) => {
        dispatch(getTDSPRates(pageNumber={pageNumber}, pageLimit={pageLimit}));
    },
    doFetchTDSPRateClasses: () => {
        dispatch(getTDSPRateClasses());
    },
    doFetchTDSPRateTypes: () => {
        dispatch(getTDSPRateTypes());
    },
    doFetchTDSPZones: () => {
        dispatch(getTDSPZones());
    },
    doFetchTDSP: () => {
        dispatch(getTDSP());
    },
    doCreateTDSPRate: (CreateNewRate, pageNumber, pageLimit) => {
        dispatch(createTDSPRate(CreateNewRate, pageNumber, pageLimit))
    },
    dispatch,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TDSPContainer);